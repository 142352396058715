<template>
  <VaNavbar class="app-layout-navbar py-0.5 px-0">
    <template #left>
      <div class="left">
        <Transition v-if="isMobile" name="icon-fade" mode="out-in">
          <VaIcon
            color="textPrimary"
            :name="isSidebarMinimized ? 'menu' : 'close'"
            size="24px"
            style="margin-top: 3px"
            @click="isSidebarMinimized = !isSidebarMinimized"
          />
        </Transition>
        <RouterLink to="/" aria-label="Visit home page">
          <VuesticLogo logo-type="horizontal" />
        </RouterLink>
      </div>
    </template>
    <template #right>
      <div v-if="userStore.isLoggedInAsUser" class="logged-in-as">
        <div class="logged-in-info">
          <span
            >Logado como: <strong>{{ userStore.email }}</strong></span
          >
          <VaButton class="mt-2 mx-auto" @click="userStore.revertToAdmin">Voltar ao Usuário</VaButton>
        </div>
      </div>
      <AppNavbarActions class="app-navbar__actions" :is-mobile="isMobile" />
    </template>
  </VaNavbar>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useGlobalStore } from '../../stores/global-store'
import AppNavbarActions from './components/AppNavbarActions.vue'
import VuesticLogo from '../VuesticLogo.vue'
import { useUserStore } from '../../stores/user-store'

const userStore = useUserStore()

defineProps({
  isMobile: { type: Boolean, default: false },
})

const GlobalStore = useGlobalStore()

const { isSidebarMinimized } = storeToRefs(GlobalStore)
</script>

<style lang="scss" scoped>
.va-navbar {
  z-index: 2;
  background-color: var(--va-primary) !important;

  @media screen and (max-width: 950px) {
    .left {
      width: 100%;
    }

    .app-navbar__actions {
      display: flex;
      justify-content: space-between;
    }
  }
}

.left {
  display: flex;
  align-items: center;
  margin-left: 1rem;

  & > * {
    margin-right: 1rem;
  }

  & > *:last-child {
    margin-right: 0;
  }
}

.icon-fade-enter-active,
.icon-fade-leave-active {
  transition: transform 0.5s ease;
}

.icon-fade-enter,
.icon-fade-leave-to {
  transform: scale(0.5);
}

.logged-in-as {
  display: flex;
  align-items: center;
  margin-right: 1rem;
  color: white;
}

.logged-in-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 0.5rem;
  border-radius: 0.5rem;
}

.revert-button {
  margin-top: 0.5rem;
  background-color: var(--va-warning);
  color: white;
  &:hover {
    background-color: rgba(255, 165, 0, 0.8);
  }
}
</style>
